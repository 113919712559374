.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 20px;
}

.login-form {
  background-color: white;
  padding: 30px;
  border-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  width: 300px;
  position: relative;
  animation: modalAppear 0.3s ease-out;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes modalAppear {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.input-group {
  margin-bottom: 30px;  // 15px에서 30px로 변경
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 20px;
  font-size: 16px;

  &:focus {
    outline: none;
    border-color: #ff4d4d;
  }
}

.login-button {
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  color: rgb(255, 0, 0);
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  // margin-top: 30px;  // 이 줄을 추가

  &:hover {
    background-color: #ff3333;
    color: white;
  }

  &:disabled {
    background-color: #ffb3b3;
    cursor: not-allowed;
  }
}

.error-message {
  color: #ff4d4d;
  font-size: 14px;
  position: absolute;
  bottom: -20px;
  left: 0;
  width: 100%;
  text-align: center;
}

.social-login {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.social-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  margin: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: white;
  cursor: pointer;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.1);
  }

  &.google {
    background-color: #DB4437;
  }

  &.kakao {
    background-color: #FEE500;
    color: #000000;
  }

  &.naver {
    background-color: #03C75A;
  }
}

.login-modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s ease, opacity 0.2s ease;

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.login-modal-content {
  background-color: transparent;
  transform: scale(0.8);
  transition: transform 0.2s ease;

  &.visible {
    transform: scale(1);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #333;
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
}

.modal-login-button {
  width: 100%;
  padding: 10px;
  background-color: #ff4d4d;
  color: white;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 30px;

  &:hover {
    background-color: #ff3333;
  }

  &:disabled {
    background-color: #ffb3b3;
    cursor: not-allowed;
  }
}
