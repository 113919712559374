.account-container {
  padding: 20px;
  height: 100%;
  background-color: #ffffff;
  border-radius: 10px;

  .tabs {
    margin-bottom: 20px;
    
    button {
      padding: 8px 16px;
      border: none;
      background: none;
      cursor: pointer;
      font-weight: bold;
      
      &.active {
        color: #1890ff;
        border-bottom: 2px solid #1890ff;
      }
    }
  }

  .account-details {
    .ant-card {
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      
      .ant-statistic {
        .ant-statistic-title {
          font-size: 14px;
          color: rgba(0, 0, 0, 0.65);
        }
        
        .ant-statistic-content {
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .account-stats {
      .stat-item {
        padding: 4px;
        
        .ant-statistic {
          .ant-statistic-title {
            font-size: 1rem;
            margin-bottom: 2px;
            color: rgba(0, 0, 0, 0.65);
          }
          
          .ant-statistic-content {
            line-height: 1;
            white-space: nowrap;
            font-size: 2rem;
          }
        }
      }
    }
  }
}
