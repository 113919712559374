.info-container {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background-color: #ffffff;
    position: relative;
  }
  
  .info-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .left-content {
    display: flex;
    align-items: center;
  }
  
  .stock-thumbnail-wrapper {
    width: 60px;
    height: 60px;
    border-radius: 30px;
    background: linear-gradient(-45deg, #df2100, #ff0c0c);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    flex-shrink: 0;
  }
  
  .stock-thumbnail {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-image: url('https://yt3.googleusercontent.com/ytc/AIdro_kutxZhVtnH4nWcW7ebuDER5TfHwPZJaqGyBVGjVC52A0A=s160-c-k-c0x00ffffff-no-rj');
    background-size: cover;
    background-position: center;
  }
  
  .stock-info {
    margin-left: 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  
  .stock-name-row {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .stock-name {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    white-space: nowrap;
  }
  
  .platform-icon {
    font-size: 24px;
    color: #FF0000;
  }
  
  .stock-ticker {
    background-color: #f0f0f0;
    padding: 3px 10px;
    border-radius: 15px;
    font-weight: bold;
    color: #333;
    font-size: 14px;
  }
  
  .hashtags {
    display: flex;
    gap: 5px;
  }
  
  .hashtag {
    background-color: #e0e0e0;
    padding: 3px 8px;
    border-radius: 12px;
    color: #666;
    font-size: 12px;
  }

  .favorite-button {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease;

    svg {
      font-size: 60%;
      color: #ddd;
      transition: all 0.3s ease;
    }

    &.active svg {
      color: red;
    }

    &:hover {
      background-color: #f0f0f0;
    }

    margin-left: 10px;
  }

.custom-drag-handle {
  position: absolute;
  top: 0;
  right: 0;
  width: 5rem;
  height: 5rem;
  background-image: linear-gradient(45deg, rgba(0,0,0,0.1) 25%, transparent 25%, transparent 50%, rgba(0,0,0,0.1) 50%, rgba(0,0,0,0.1) 75%, transparent 75%, transparent);
  background-size: 50px 50px;
  cursor: move;
}

.right-content {
  display: flex;
  align-items: center;
}

.price-change-container {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.candle-wrapper {
  position: relative;
  width: 8px;
  margin-right: 10px;
}

.candle-wick {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
}

.candle-body {
  position: absolute;
  left: 0;
  width: 100%;
}

.price-change-percentage {
  font-weight: bold;
  white-space: nowrap;
}
