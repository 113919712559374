.main {
    width: 100vw;
    height: 100vh; // 90vh에서 100vh로 변경
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden; // 전체 컨테이너의 오버플로우를 숨깁니다.
}

.Navbar {
    height: 50px;
    width: 100%;
    flex-shrink: 0; // Navbar의 크기가 줄어들지 않도록 합니다.
}

.content {
    flex: 1; // flex-grow: 1, flex-shrink: 1, flex-basis: 0%를 한 번에 설정
    width: 100%;
    background-color: rgb(240, 240, 240);
    overflow-x: hidden;
    overflow-y: scroll; // 세로 스크롤을 허용합니다.
    scrollbar-width: none; // Firefox에서 스크롤바를 숨깁니다.
    -ms-overflow-style: none; // IE와 Edge에서 스크롤바를 숨깁니다.

    // Chrome, Safari, Opera에서 스크롤바를 숨깁니다.
    &::-webkit-scrollbar {
        display: none;
    }

    position: relative;
}

.height-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    span {
        font-size: 48px;
        color: white;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 10px 20px;
        border-radius: 10px;
    }
}

.toggle-overlay {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1001;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
}

.Footer {
    height: 20px;
    width: 100%;
    flex-shrink: 0; // Footer의 크기가 줄어들지 않도록 합니다.
}
