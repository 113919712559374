@font-face {
    font-family: 'LogoFont';
    src: url('../../assets/font/Chonky_Pumpkin.ttf') format('truetype');
}

.Nav {
    height: 100%;
    width: 100%;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 1000;
}

.logo {
    margin-left: 30px;
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    user-select: none;
    position: relative;
    display: inline-block;
}

.search-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin: 0 20px;
}

.custom-input {
    width: 300px;
    margin: 0 10px;
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
    transition: all 0.3s ease;

    .ant-input {
        height: 100%;
        font-size: 16px;
        padding: 0 20px;
        padding-right: 40px;
        transition: all 0.3s ease;
    }

    .ant-input-affix-wrapper {
        border-radius: 20px;
        transition: all 0.3s ease;

        &:focus,
        &-focused {
            border-color: #ff0000;
            box-shadow: 0 0 0 2px rgba(255, 0, 0, 0.2);
        }
    }

    .search-button {
        position: absolute;
        right: 2px;
        top: 50%;
        transform: translateY(-50%);
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background-color: #ff0000;
        color: white;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #d70000;
        }

        .anticon {
            font-size: 18px;
        }
    }
}

.plus-button {
    width: 32px; // 검색 버튼과 동일한 크기로 조정
    height: 32px;
    border-radius: 50%;
    background-color: #ff0000;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    // margin-left: 2px; // 검색창과의 간격을 더 좁힘

    &:hover {
        background-color: #d70000;
    }
}

.nav-buttons {
    display: flex;
    gap: 10px;
    margin-left: auto;
    margin-right: 30px;
}

.nav-button {
    min-width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 20px;
    font-size: 16px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
}

.game-start-button {
    background-color: #ff0000;
    color: rgb(255, 255, 255);
    border: none;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        background-color: #d70000;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        color: #ffc0cb;
    }
}

.logo-text {
    font-family: 'LogoFont', sans-serif;
    font-size: 30px;
    color: #ff0000;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    user-select: none;
    pointer-events: none;
    position: relative;
    display: inline-block;
}

// .login-button {
//     width: 100px;
//     height: 40px;
//     border-radius: 20px;
//     transition: all 0.3s ease;
//     overflow: hidden;
//     padding: 0;
//     border: none;
//     cursor: pointer;
//     background-color: #2041ff;
//     color: #ffffff;

//     &.logged-in {
//         width: 40px;
//         border-radius: 50%;
//     }

//     .profile-image {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//     }
// }

.beta-tag {
    position: absolute;
    top: 0.0em;
    right: -3em;
    font-family: 'Segoe UI', sans-serif;
    font-style: italic;
    font-size: 0.5em;
    font-weight: bold;
    color: #ff0000;
    background-color: #ffffff;
    padding: 0.1em 0.3em;
    border-radius: 0.2em;
    border: 1px solid #ff0000;
    transform: rotate(20deg);
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.custom-modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  width: 300px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.tree-map-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ff0000;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 2px;

    &:hover {
        background-color: #d70000;
    }
}

.rotated-icon {
//   transform: rotate(180deg);
  font-size: 20px;
  display: flex;
  align-items: center;
}

.game-start-wrapper {
  position: relative;
  display: inline-block;

  .game-start-button.disabled {
    background-color: #ff4d4f;
    color: white;
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
  }

  .beta-sticker {
    position: absolute;
    top: 0px;  // -10px에서 0px로 수정
    right: -10px;
    background-color: #ffd700;
    color: #000;
    padding: 2px 10px;
    font-size: 12px;
    transform: rotate(15deg);
    box-shadow: 2px 2px 5px rgba(0,0,0,0.2);
    border-radius: 3px;
    font-weight: bold;
    z-index: 1;
  }
}

.nav-login-button {
    background-color: #1677ff;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 24px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    // margin-right: 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(22, 119, 255, 0.2);

    &:hover {
        background-color: #4096ff;
        box-shadow: 0 4px 8px rgba(22, 119, 255, 0.3);
        transform: translateY(-1px);
    }

    &:active {
        background-color: #0958d9;
        transform: translateY(1px);
        box-shadow: 0 1px 2px rgba(22, 119, 255, 0.2);
    }

    &:focus {
        outline: none;
        box-shadow: 0 0 0 2px rgba(22, 119, 255, 0.2);
    }

    &.disabled {
        background-color: #bae0ff;
        cursor: not-allowed;
        opacity: 0.7;
        pointer-events: none;
    }
}
