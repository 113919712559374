.chart-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.chart-options {
    width: 100%;
    height: 40px;
    // background-color: rgb(105, 255, 178);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    // padding: 0 10px;
}

.chart-title {
    font-weight: bold;
    padding-left: 20px;
}

.chart-controls {
    display: flex;
    align-items: center;
    padding-right: 4px;
}

.ant-space-item {
    margin-left: 10px;
}

.chart-component-wrapper {
    // background-color: rgb(255, 132, 132);   
    flex-grow: 1;
    width: 100%;
    height: calc(100% - 40px); // chart-options의 실제 높이를 뺀 나머지
}

