.Test {
    background-color: red;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    white-space: normal;
    word-wrap: break-word;
    

    
    /* 웹킷 기반 브라우저(Chrome, Safari 등)에서 스크롤바 숨기기 */
    &::-webkit-scrollbar {
        display: none;
    }
    
    /* IE, Edge, Firefox에서 스크롤바 숨기기 */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}