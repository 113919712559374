.subinfo-container {
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.subinfo-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.stock-thumbnail-wrapper {
  width: 108px;
  height: 108px;
  border-radius: 54px;
  background: linear-gradient(-45deg, #df2100, #ff0c0c);
  display: flex;
  justify-content: center;
  align-items: center;
}

.stock-thumbnail {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: url('https://yt3.googleusercontent.com/ytc/AIdro_kutxZhVtnH4nWcW7ebuDER5TfHwPZJaqGyBVGjVC52A0A=s160-c-k-c0x00ffffff-no-rj');
  background-size: cover;
  background-position: center;
}

.stock-ticker {
  background-color: #f0f0f0;
  padding: 5px 15px;
  border-radius: 25px;
  font-weight: bold;
  color: #333;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 15px 0;
}

.stock-description {
  font-size: 14px;
  line-height: 1.5;
  color: #666;
}

.info-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  margin-top: 15px;
}

.info-item {
  background-color: #f0f0f0;
  border-radius: 15px;
  padding: 10px;
  text-align: center;

  h3 {
    font-size: 14px;
    margin-bottom: 5px;
    color: #333;
  }

  p {
    font-size: 16px;
    font-weight: bold;
    color: #666;
  }
}
