.position-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.position-tabs {
    display: flex;
    flex-shrink: 0;

    button {
        padding: 5px 10px;
        margin-right: 10px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        font-size: 1rem;

        &.active {
            font-weight: bold;
            border-bottom: 2px solid #000;
        }
    }
}

.position-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 30px 5px 20px; 
    background-color: #ffffff;
    font-size: 1.2rem;
    border-bottom: 1px solid #cccccc;
    span {
        flex: 1;
        text-align: left;

        &:last-child {
            text-align: right;
        }
    }
}

.position-list {
    flex: 1;
    overflow-y: auto;
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 5px;
    .position-item:last-child + .position-divider {
        display: none;
    }
}

.position-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    transition: background-color 0.3s ease;
    position: relative;

    &.profitable {
        background-color: rgba(0, 255, 0, 0.1);
    }

    &.loss {
        background-color: rgba(255, 0, 0, 0.1);
    }

    &.top-three {
        font-weight: bold;
        font-size: 1.5rem;
    }

    .profit-bar {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 8px;
        height: 100%;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    span {
        flex: 1;
        text-align: left;

        &:last-child {
            text-align: right;
        }

        &.profit {
            color: rgb(0, 200, 0);
        }

        &.loss {
            color: red;
        }
    }
}

.position-divider {
    height: 1px;
    background-color: #e0e0e0;
    margin: 0 10px;
}

.custom-grid-handle {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 12px;
    color: rgba(122, 122, 122, 0.466);
    cursor: move;
    user-select: none;
}

.drag-handle-text, .item-info {
    pointer-events: none;
}

.position-top-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 5px 10px; 
    background-color: #ffffff;
    font-size: 1rem;
}

.position-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 30px 5px 20px; 
    background-color: #ffffff;
    font-size: 1rem;
    border-bottom: 1px solid #cccccc;
    span {
        flex: 1;
        text-align: left;

        &:last-child {
            text-align: right;
        }
    }
}
