$default-padding-right: 5px;
$hover-padding-right: 30px;

.favorite-container {
  // height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.favorite-top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  background-color: #ffffff;
  font-size: 1rem;
}

.favorite-title {
  display: flex;
  align-items: center;

  .heart-icon {
    color: red;
    margin-right: 5px;
  }
}

.favorite-tabs {
  display: flex;
  justify-content: flex-end;

  button {
    padding: 5px 10px;
    margin-left: 10px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 1rem;

    &.active {
      font-weight: bold;
      border-bottom: 2px solid #000;
    }
  }
}

.drag-handle-container {
  height: 100%;
  flex-grow: 1;
  cursor: move;
}

.divider {
  height: 1px;
  background-color: #e0e0e0;
  margin: 0 10px;
}

.favorite-list {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.favorite-item {
  display: flex;
  align-items: center;
  height: 30px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  // padding-right: $default-padding-right;

  

  .candle-wrapper {
    width: 10px;
    height: 20px;
    position: relative;
    margin-right: 10px;
  }

  .candle-wick, .candle-body {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .candle-wick {
    width: 2px;
  }

  .candle-body {
    width: 6px;
  }

  .stock-name {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .price-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 220px;
    // margin-right: 10px;

    .current-price {
      margin-right: 10px;
      width: 80px;
      text-align: right;
    }

    .price-change {
      display: flex;
      width: 130px;
      justify-content: flex-end;

      .change-arrow {
        width: 20px;
        text-align: center;
      }

      .change-amount {
        width: 50px;
        text-align: right;
        margin-right: 10px;
      }

      .change-percent {
        width: 50px;
        text-align: right;
        position: relative;

        &::before {
          content: attr(data-value);
          position: absolute;
          right: 0;
          top: 0;
        }
      }
    }
  }

  .handle-container {
    position: relative;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10px;
    opacity: .3;
    transition: all 0.3s;
    width: 10px;
    height: 100%;
  }

  .handle-icon {
    cursor: grab;
    color: rgba(0, 0, 0, 0.5);
  }

  .handle-container:hover {
    opacity: 1;
    width: 20px;
  }

  &.dragging {
    opacity: 0.5;
    background-color: #f0f0f0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  
}
