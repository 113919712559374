.trading-container {
    padding: 10px;
    background-color: #ffffff;
    border-radius: 10px;
    height: 100%;
    overflow-y: auto;
    min-width: 200px; // 최소 너비 설정

    .trading-stats {
        padding: 0 10px;
        margin-bottom: 20px;

        .ant-statistic {
            .ant-statistic-title {
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
            }
            
            .ant-statistic-content {
                color: rgba(0, 0, 0, 0.85);
                font-size: 16px;
                font-weight: bold;
            }
        }
    }
}

.trading-header {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    padding-left: 5px;
}

.trading-title {
    padding-left: 5px;
}

.drag-handle-container {
    user-select: none;
}

.trading-form {
    .form-item {
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        width: 100%;

        label {
            font-weight: bold;
            width: 80px;
            flex-shrink: 0;
        }

        > :last-child {
            flex-grow: 1;
            min-width: 0;
            width: 100%;
        }

        span {
            white-space: nowrap; // 줄바꿈 방지
            &.value {
                font-weight: bold;
                margin-left: auto; // 오른쪽 정렬
            }
        }

        .ant-switch {
            flex-grow: 0;
            // width: 33.33% !important;
            // width: 60px;
        }

        .ant-segmented {
            width: 100%;
            .ant-segmented-item {
                min-width: 0;
                flex: 1;
            }
        }
    }

    .price-input,
    .quantity-input {
        display: flex;
        align-items: center;
        width: 100%;

        .ant-input {
            flex: 1;
            margin: 0 8px;
            min-width: 0;
        }

        .ant-btn {
            flex-shrink: 0;
        }
    }

    .ant-radio-group {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .value {
        font-weight: bold;
    }
}

.trading-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    .ant-btn {
        width: 48%;
    }
}

.ant-select,
.ant-input,
.ant-radio-button-wrapper,
.ant-btn {
    border-radius: 8px;
}

.ant-input-group-addon {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
