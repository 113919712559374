.treemap-modal {
  .ant-modal-content {
    border-radius: 50px;
    position: relative;
    user-select: none;
  }

  .ant-modal-close {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 50px;
    height: 50px;
    background-color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    border-radius: 50%;

    &:hover {
      background-color: rgb(255, 255, 255);
      transform: scale(1.2);
    }

    .ant-modal-close-x {
      font-size: 30px;
      line-height: 50px;
      color: #333;
    }
  }
}

.treemap-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  // border-radius: 35px;
  overflow: hidden;
}
