.react-resizable {
  position: relative;
}
.react-resizable-handle {
  position: relative;
  width: 30px;
  height: 30px;
  background-repeat:no-repeat ;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' style='background-color:%23ffffff00' x='0px' y='0px' width='6px' height='6px' viewBox='0 0 8 8'%3E%3Cg opacity='0.302'%3E%3Cpath fill='%23000000' d='M 2 8 A 8 8 0 0 0 8 2 A 1 1 0 0 0 6 1.5 A 6 6 0 0 1 1.5 6 A 1 1 0 0 0 2 8 Z'%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
  background-position: bottom right;
  background-size: 35%;
  padding: 0 4px 4px 0;
}
.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}
.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}
.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}
.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}
.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}
.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}
.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}
.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}
.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}
.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}