.footer {
    height: 20px; // 높이를 20px로 설정
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    font-size: 10px; // 글자 크기를 줄임
    color: #a3a3a3ab;
    overflow: hidden; // 내용이 넘치지 않도록 함
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 10px;
}

.footer-left, .footer-right {
    display: flex;
    align-items: center;
}

.footer-right {
    a {
        color: #66666685;
        text-decoration: none;
        margin-left: 10px;
        cursor: pointer;
        user-select: none;
        
        &:hover {
            text-decoration: underline;
        }
    }
}

.founder-modal {
  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .ant-modal-body {
    flex-grow: 1;
    overflow-y: auto;
    user-select: none;
  }
  
  .founder-statement {
    user-select: none;
  }
}

.founder-statement {
  text-align: left;
  font-size: 16px;
  line-height: 1.6;
  
  
  p {
    margin-bottom: 20px;
  }
  
  strong {
    font-weight: 700;
  }
}
