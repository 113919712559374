.sign-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.sign-ticker-wrapper {
  width: 100%;
  overflow: hidden;
}

.sign-ticker {
  display: inline-flex;
  white-space: nowrap;
  width: 150%;
}

.stock-item {
  display: inline-flex;
  align-items: center;
  padding: 0 5px;
  // min-width: 200px;
}

.stock-name, .stock-price, .stock-change, .stock-arrow {
  margin-right: 10px;
}

.positive {
  color: #00cf07;
}

.negative {
  color: #f44336;
}
