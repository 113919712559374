@font-face {
  font-family: 'LogoFont';
  src: url('../../assets/font/Chonky_Pumpkin.ttf') format('truetype');
}

.search-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.add-content {
    background-color: rgb(255, 24, 24);
    padding: 30px;
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    width: 300px;
    position: relative;
    animation: modalAppear 0.3s ease-out;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    h2 {
      margin-bottom: 20px;
    }
  }

  .large-text {
    font-size: 4em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .small-text {
    font-size: 2em;
    margin-bottom: 20px;
    color: #999999;
  }
  
  .add-logo {
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
  }

  .add-logo-text {
    font-family: 'LogoFont', sans-serif;
    font-size: 70px;
    color: #ff0000;
    text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    user-select: none;
    pointer-events: none;
  }

  .add-beta-tag {
    position: absolute;
    top: 0.8em;
    right: -2em;
    font-family: 'Segoe UI', sans-serif;
    font-style: italic;
    font-size: 1.3em;
    font-weight: bold;
    color: #ff0000;
    background-color: #ffffff;
    padding: 0.05em 0.3em 0.1em 0.3em; 
    border-radius: 0.2em;
    border: 1px solid #ff0000;
    transform: rotate(20deg);
  }

.search-input-container {
  width: 50%;
  margin-top: 20px;
}

.search-input {
  width: 100%;
  height: 80px;
  border-radius: 40px;
  border: 1px solid #ff0000;
  padding: 0 20px;
  font-size: 1.5rem;
  text-align: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
  outline: none;

  &::placeholder {
    text-align: center;
  }
}

.add-search-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #ff0000;
  color: white;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 30px;
  margin-bottom: 70px;

  &:hover {
    background-color: #d70000;
  }

  svg {
    font-size: 24px;
  }
}
