.App {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}


// 텍스트 선택 가능하게 하는 코드
// .selectable-text {
//   user-select: text;
//   -webkit-user-select: text;
//   -moz-user-select: text;
//   -ms-user-select: text;
// }