.react-grid-layout {
  background: rgba(250, 250, 250, 1);
  height: 100%;
  margin: 0px;
}

.react-grid-item:not(.react-grid-placeholder) {
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); // 그림자 추가
}

.react-grid-item.react-grid-placeholder {
  background-color: #ffffff;
  opacity: 0.2;
}

.gridContainer::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera용 */
}

.grid-item {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.drag-handle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // background-color: rgb(255, 92, 92);
  color: rgba(122, 122, 122, 0.466);
  padding: 2px 5px; // 패딩을 줄임
  font-size: 12px; // 폰트 크기를 줄임
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  height: 16px; // 높이를 명시적으로 지정
  cursor: pointer; // 마우스 오버 시 포인터로 변경
  // transition: background-color 0.3s ease; // 부드러운 전환 효과 추가

  // &:hover {
  //   background-color: rgba(200, 200, 200, 0.2); // 호버 시 배경색 변경
  // }
}

.item-info {
  font-size: 9px; // 글자 크기를 더 줄임
  color: #999;
  line-height: 16px; // drag-handle의 새로운 높이와 일치
  position: absolute;
  right: 5px; // 오른쪽 여백을 줄임
  z-index: 3;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 0 3px; // 내부 여백을 줄임
  border-radius: 2px;
  margin-left: auto;
}

.drag-handle-text {
  font-weight: bold;
  font-size: 10px; // 폰트 크기를 줄임
  line-height: 16px; // drag-handle의 새로운 높이와 일치
}

.component-container {
  width: 100%;
  height: 100%; // drag-handle 높이에 맞춰 조정
  overflow: hidden; // 스크롤 방지
}

.react-grid-item {
  background-color: #ffffff;
}